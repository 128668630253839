import React from "react"
import { graphql } from 'gatsby'

import Header from "../../components/header/header"

import '../reset.css'

export default function Blog(props) {

  const articles = props.data.allPrismicBlogArticle.nodes;

  console.log(articles)

  let articleCards = articles.map((article, index) => {
    return <div key={index}><a href={article.uid}>{article.data.title.text}</a></div>
  })

  return (
    <div style={{textAlign: "center", margin: "0", padding: "0"}}>
      
      <Header />

      <img src="/images/logo.png" style={{maxWidth: "300px", marginBottom: "25px", marginTop: "25px"}} />    
      
      {articleCards}      

    </div>)
  
}

export const query = graphql`
  {  
    allPrismicBlogArticle {
      nodes {
        uid
        data {
          body {
            text
          }
          subtitle {
            text
          }
          title {
            text
          }
        }
      }
    }
  }
`